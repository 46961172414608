import type { AuthMethod } from '@hubcms/domain-auth';
import type { GtmCode } from '@hubcms/domain-tracking';

export const timeZone = 'Europe/Luxembourg';
export const gtmCodes: GtmCode[] = [{ code: 'GTM-MJWKBXZ', isParent: true }, { code: 'GTM-K2P93RR' }];
export const brandCode = 'lt';
export const adunitBrandCode = 'Luxtimes';
export const b2bUrlBrandCode = 'luxtimes';
export const b2bDMPMetaTagBrandCode = 'stp';
export const headerDateFormat = 'EEEE, MMMM d yyyy';
export const authorPrefix = 'authors/';
export const tagsPrefix = 'tag/';
export const authMethod: AuthMethod = 'oauth';
export const cimIdentifier = 'css1NMcpwZt2maOBK2hHcPWo.u1FlbtRnf5xVl77rof.07';
export const cimLanguage = 'EN';
export const tickarooClientId = '64772af30bfac400a065fb8b';
export const heroBlockIntroProps = {
  size: 'lg',
  weight: 'strong',
};
export const domain = 'luxtimes.lu';
export const enablePersonalizedArticleList = true;
export const subscriptionButtonAppearance = 'secondary';
export const isAppTopicsMenuEnabled = false;
export const infiniteArticleListLoadMoreLabel = 'Read more articles';
